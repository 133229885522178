import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class OnlineOfflineService {
  // Subject to Manage RxJS event according to the online or offline status
  public connectionChanged$: Observable<boolean>;

  private internalConnectionChanged = new Subject<boolean>();

  get isOnline() {
    return !!window.navigator.onLine;
  }

  constructor() {
    window.addEventListener('online', () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
    this.connectionChanged$ = this.internalConnectionChanged.asObservable();
  }

  // Lorsqu'un événement sur la fenêtre se présente, updateOnlineStatus() est exécuté et déclenche notre objet booléen.
  private updateOnlineStatus(status: boolean) {
    this.internalConnectionChanged.next(status);
  }
}
