import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUserService} from './current-user.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    public route: Router,
    protected currentUserService: CurrentUserService,
    private idle: Idle,
    private keepalive: Keepalive,
  ) {}

  idleCount(idleTime: number) {
    if(localStorage.userToken && localStorage.keepActive === 'false') {
      this.idle.setIdle(idleTime);
      this.idle.setTimeout(1);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onIdleEnd.subscribe(() => {
        this.reset();
      });

      this.idle.onTimeout.subscribe(() => {
        this.timedOut = true;
        this.autoLogout();
      })

      this.keepalive.interval(15);
      this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

      this.reset();
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started';
    this.timedOut = false;
  }

  autoLogout() {
    this.currentUserService.logout().subscribe(
      () => this.route.navigate(['login'])
    )
  }
}
