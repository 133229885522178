import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {EnvLoaderService} from './_services/env-loader.service';
import {CommonModule, registerLocaleData} from '@angular/common';
import {LegalsModalComponent} from './_components/modals/legals-modal/legals-modal.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ChartsModule} from 'ng2-charts';
import {EvaluationSurveyModule} from './_pages/evaluation-survey/evaluation-survey.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import localeFr from '@angular/common/locales/fr';

import {
  API_CLIENT,
  ApiBridgeModule,
  CURRENT_USER_SERVICE, ErrorInterceptor,
  HeadersInterceptor,
  HeadersService,
  TokenService
} from '@forlabs/api-bridge';
import {ApiService} from './_services/api.service';
import {CurrentUserService} from './_services/current-user.service';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';


registerLocaleData(localeFr, 'fr');
export function init_app(envLoaderService: EnvLoaderService) {
  return () => envLoaderService.init();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LegalsModalComponent,
  ],
  entryComponents: [],
  imports: [
    ApiBridgeModule,
    ChartsModule,
    CommonModule,
    BrowserModule,
    MatSnackBarModule,
    EvaluationSurveyModule,
    IonicModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    EnvLoaderService,
    TokenService,
    HeadersService,
    {provide: API_CLIENT, useClass: ApiService},
    {provide: CURRENT_USER_SERVICE, useClass: CurrentUserService},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: init_app, deps: [EnvLoaderService], multi: true},
    LocalNotifications
  ],
  bootstrap: [AppComponent],
  exports: [
    TranslateModule,
  ],
})
export class AppModule {
}
