import {Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {AbstractEvaluationInput} from './abstract-evaluation-input';
import {EvaluationService} from '../../../_services/evaluation.service';
import {Survey} from '../../../_models/evaluation.model';

@Component({
  selector: 'app-bool',
  template: `
    <ion-row class="radio-col">
      <ion-col *appLet="getBaseDomId() + entry.responses[0].toString() as id">
        <input #choiceRangeSubmitBtn appPreventDoubleTap class="validation-btn"
               (debounceClick)="submitValue(entry.slug, 1)"
               #input type="radio" [name]="entry.slug" [id]="id" value="1"/>
        <label [for]="id" class="sfp700 thumbs-container">
          <ion-img src="assets/icon/eval-form-icons/thumb-up.png"></ion-img>
          <ion-text>OUI</ion-text>
        </label>
      </ion-col>
      <ion-col *appLet="getBaseDomId() + entry.responses[1].toString() as id">
        <input #choiceRangeSubmitBtn appPreventDoubleTap class="validation-btn"
               (debounceClick)="submitValue(entry.slug, 0)"
               #input type="radio" [name]="entry.slug" [id]="id" value="0"/>
        <label [for]="id" class="sfp700 thumbs-container">
          <ion-img src="assets/icon/eval-form-icons/thumb-down.png"></ion-img>
          <ion-text>NON</ion-text>
        </label>
      </ion-col>
    </ion-row>
  `,
  styleUrls: ['../abstract-evaluation-survey.component.scss']
})
export class BoolComponent extends AbstractEvaluationInput {
  @Input() public entry: {
    id: string;
    name: string;
    responses: string[];
    slug: string;
    text: string;
    type: string;
  };
  @Input() public surveyType: Survey;
  @ViewChildren('input') public inputs: QueryList<ElementRef>;

  constructor(
      protected evaluationService: EvaluationService,
  ) {
    super(evaluationService);
  }

}
