import {AbstractUser} from '@forlabs/api-bridge';
import {Investigator} from './investigator.model';
import {Type} from '@decahedron/entity';
import {Center} from './center.model';
import {Appointment} from './appointment.model';
import {Evaluation} from './evaluation.model';
import {Role} from './interfaces.model';

// // TODO: put parseDate in apibridge and fix Type<any> in Normalizer, then remove this from here.
// const parseDate: (str: string | null) => (Date | null) = (str: string | null) => str !== null ? new Date(str) : null;
// const NormalizerAny = <T1, T2>(normalizer: (initialValue: T1) => T2) => {
//   return Normalizer<T1, T2>(normalizer) as (target: any, key: string) => void;
// };

export class Patient extends AbstractUser {
  public get uri(): string {
    return Patient.getUriById(this.id);
  }

  public reference: string = null;
  // public username: string = null;
  // public password: string = null;
  @Type(Center) public center: Center = null;
  @Type(Investigator) public investigator: Investigator = null;
  @Type(Appointment) public appointment: Appointment[] = null;
  public enteredAt: string = null;
  public leftAt: string = null;
  public approvedLegalNotices: string = null;
  public crisis: string[] = null;
  @Type(Evaluation) public evaluations: Evaluation[] = null;
  public role: Role = null;
  public latestAutoEvaluation: Evaluation = null;
  public autoEvaluationsNumber: number = null;
  public crisisNumber: number = null;

  public static getEntityName(): 'api/patients' {
    return 'api/patients';
  }

  public getRoles(): string[] {
    return [this.role];
  }
}
