import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';


interface LetContext<T> {
  appLet: T;
}

@Directive({
  selector: '[appLet]',
})
export class LetDirective<T> {
  protected context: LetContext<T> = {appLet: null};

  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
    _viewContainer.createEmbeddedView(_templateRef, this.context);
  }

  @Input()
  public set appLet(value: T) {
    this.context.appLet = value;
  }
}
