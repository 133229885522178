import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Patient} from '../_models/patient.model';
import {Question} from '../_models/question.model';
import {AbstractApiClient} from '@forlabs/api-bridge';
import {OnlineOfflineService} from './online-offline.service';
import {ApiRequestQueueService} from './api-request-queue.service';
import {Evaluation} from '../_models/evaluation.model';
import {Appointment} from '../_models/appointment.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService extends AbstractApiClient {
  public constructor(
    httpClient: HttpClient,
    private onlineOfflineService: OnlineOfflineService,
    private apiRequestQueueService: ApiRequestQueueService,
  ) {
    super(httpClient);
    this.apiRequestQueueService.initApiService(this);
  }

  public getApiUrl(): string {
    return environment.environment.apiUrl;
  }

  public getCurrentUser(): Observable<Patient> {
    return this.getEntity(Patient, null, 'api/patients/getCurrentUser');
  }

  public getQuestion(id: string): Observable<Question> {
    return this.getEntity(Question, id, 'api/questions');
  }

  public getQuestions(): Observable<Question[]> {
    // return this.get<Question[]>('api/questions')
    //   .pipe(map(jsonData => EntityBuilder.buildMany<Question>(Question, Object.values(jsonData))))
    // ;
    return this.getEntityCollection(Question, 'api/questions');
  }

  public getPatient(id: string): Observable<Patient> {
    return this.getEntity(Patient, id, `api/patients/${id}`);
  }

  public getPatients(): Observable<Patient[]> {
    return this.getEntityCollection(Patient, 'api/patients');
  }

  public getAppointment(id: string): Observable<Appointment> {
    return this.getEntity(Appointment, id, 'api/appointments');
  }

  public getAppointments(): Observable<Appointment[]> {
    return this.getEntityCollection(Appointment, 'api/appointments');
  }

  public getEvaluation(id: string): Observable<Evaluation> {
    return this.getEntity(Evaluation, id, 'api/evaluations');
  }

  public getEvaluations(): Observable<Evaluation[]> {
    return this.getEntityCollection(Evaluation, 'api/evaluations');
  }

  public postEvaluation(data): Observable<Record<string, any> | {status: 'queued'}> {
    if (this.onlineOfflineService.isOnline) {
      return this.post('api/evaluations', data);
    } else {
      this.apiRequestQueueService.queueRequest('POST', 'api/evaluations', data);
      return of({status: 'queued'});
    }
  }
}
