import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './_guards/auth.guard';
import {LoginGuard} from './_guards/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./_pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./_pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'forms-menu',
    loadChildren: () => import('./_pages/forms-menu/forms-menu.module').then( m => m.FormsMenuPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./_pages/account/account.module').then( m => m.AccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stats',
    loadChildren: () => import('./_pages/stats/stats.module').then( m => m.StatsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'evaluations',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'auto-evaluation-survey',
        loadChildren: () => import('./_pages/evaluation-survey/auto-evaluation-survey/auto-evaluation-survey.module')
          .then( m => m.AutoEvaluationSurveyPageModule)
      },
      {
        path: 'crisis-survey',
        loadChildren: () => import('./_pages/evaluation-survey/crisis-survey/crisis-survey.module')
          .then( m => m.CrisisSurveyPageModule)
      },
    ]
  },
  {
    path: 'presentation',
    loadChildren: () => import('./_pages/presentation/presentation.module').then( m => m.PresentationPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
