import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Patient} from '../_models/patient.model';
import {CurrentUserService} from '../_services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.currentUserService.getCurrentUser().pipe(
      filter((user: Patient | null | undefined) => user !== undefined),
      map((currentUser) => {
        if (currentUser && currentUser instanceof Patient) {
          // Already logged in, redirect to /home
          return this.router.createUrlTree(['/home'], {queryParams: {returnUrl: state.url}});
        }

        // Not connected, can activate
        return true
      }),
    );
  }

}
