import {NgModule} from '@angular/core';
import {BoolComponent} from './evaluation-input/bool.component';
import {ChoiceComponent} from './evaluation-input/choice.component';
import {ChoiceRangeComponent} from './evaluation-input/choice-range.component';
import {RangeComponent} from './evaluation-input/range.component';
import {ChatbotComponent} from './chatbot.component';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {AbstractEvaluationSurveyComponent} from './abstract-evaluation-survey.component';
import {LetDirective} from '../../_directives/let.directive';
import {PreventDoubleTapDirective} from '../../_directives/prevent-double-tap.directive';

@NgModule({
  declarations: [
    BoolComponent,
    AbstractEvaluationSurveyComponent,
    ChoiceComponent,
    ChoiceRangeComponent,
    RangeComponent,
    ChatbotComponent,
    LetDirective,
    PreventDoubleTapDirective,
  ],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    RouterModule,
  ],
  exports: [
    AbstractEvaluationSurveyComponent,
    BoolComponent,
    ChoiceComponent,
    ChoiceRangeComponent,
    RangeComponent,
    ChatbotComponent,
    LetDirective
  ]
})
export class EvaluationSurveyModule{}
