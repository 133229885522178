import {Injectable, Input} from '@angular/core';
import {Survey} from '../../../_models/evaluation.model';
import {EvaluationService} from '../../../_services/evaluation.service';

@Injectable()
export abstract class AbstractEvaluationInput {

  protected static latestId = 0;
  @Input() public entry: {
    id: string;
    name: string;
    responses: string[];
    slug: string;
    text: string;
    type: string;
  };
  public surveyType: Survey;
  protected inputs = null;
  protected id: number;

  protected constructor(protected evaluationService: EvaluationService) {
    this.id = AbstractEvaluationInput.latestId++;
  }

  public reset(entryType: string): void {
    if (!this.inputs) {
      return;
    }
    this.inputs.forEach((domInput) => {
        domInput.nativeElement.checked = false;
    });
  }

  public getBaseDomId(): string {
    return `auto-evaluation-input-${this.id}-`;
  }

  public submitValue(slug, value) { // Typehint
    this.evaluationService.submitResponse(this.surveyType, slug, value);
  }
}
