import {Component, ElementRef, Input, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {AbstractEvaluationInput} from './abstract-evaluation-input';
import {EvaluationService} from '../../../_services/evaluation.service';
import {Survey} from '../../../_models/evaluation.model';

@Component({
  selector: 'app-choice-range',
  template: `
    <ion-row>
      <div class="select-container" [ngClass]="selectedValue !== null ? 'select-container-active' : 'select-container'">
        <div>
          <div (click)="toggleAccordion()" class="response-select">
            <div class="text">{{selectedLabel}}</div>
            <ion-icon [name]="accordionExpanded? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          </div>
          <div #input class="dropdown" #dropDown>
            <div class="dropdown-select text"
                 [class]="indexOfElement+1 === entry.responses.length ? 'last-dropdown' : ''"
                 *ngFor="let answer of entry.responses; let indexOfElement = index"
                 (click)="getValue(answer, indexOfElement+1)">
              {{answer}}
            </div>
          </div>
        </div>
      </div>
      <ion-button #choiceRangeSubmitBtn appPreventDoubleTap class="validation-btn"
                  [debounceTime]="700" (debounceClick)="submitValue(entry.slug, this.selectedValue)"
                  [disabled]="selectedValue === null ? 'true' : 'false'">
        Valider
      </ion-button>
    </ion-row>
  `,
  styleUrls: ['../abstract-evaluation-survey.component.scss']
})
export class ChoiceRangeComponent extends AbstractEvaluationInput {
  @Input() public entry: {
    id: string;
    name: string;
    responses: string[];
    slug: string;
    text: string;
    type: string;
  };
  @Input() public surveyType: Survey;
  @ViewChild('dropDown') dropdown: ElementRef;
  @ViewChildren('input') public inputs: QueryList<ElementRef>;
  public accordionExpanded: boolean;
  public selectedValue: string;
  public selectedLabel: string;
  public count = 0;

  constructor(
    protected renderer: Renderer2,
    protected evaluationService: EvaluationService,
  ) {
    super(evaluationService);
    this.initVal();
  }

  public toggleAccordion() {
    if (this.accordionExpanded) {
      this.renderer.setStyle(this.dropdown.nativeElement, 'max-height', '0px');
      this.renderer.setStyle(this.dropdown.nativeElement, 'display', 'none');
    } else {
      this.renderer.setStyle(this.dropdown.nativeElement, 'max-height', '800px');
      this.renderer.setStyle(this.dropdown.nativeElement, 'display', 'unset');
    }
    this.accordionExpanded = !this.accordionExpanded;
  }

  public getValue(answer, index) {
    this.selectedValue = index;
    this.selectedLabel = answer;
    this.toggleAccordion();
  }

  public reset(entryType: string): void {
    this.initVal();
  }

  public initVal() {
    this.selectedLabel = 'Choisir une réponse';
    this.selectedValue = null;
    this.accordionExpanded = false;
  }

}
