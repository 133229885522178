import {AbstractEntity} from '@forlabs/api-bridge';
import * as moment from 'moment';


export type Survey = 'auto-evaluation' | 'crisis';
export const NUM_STEPS: Record<Survey, number> = {
  ['auto-evaluation']: 13,
  crisis: 4,
};

export class Evaluation extends AbstractEntity {
  public survey: Survey = null;
  public responses: Record<any, number> = null;
  public date: string = null;
  public score: number = null;
  public patient: string = null; // Patient IRI

  public isOld(): boolean {
    return moment(this.date).isBefore(moment().subtract(1, 'hour'));
  }
}
