import {Injectable} from '@angular/core';
import {EntityBuilder} from '@decahedron/entity';
import {Evaluation, NUM_STEPS, Survey} from '../_models/evaluation.model';
import * as moment from 'moment';
import {CurrentUserService} from './current-user.service';
import {Observable, of, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Patient} from '../_models/patient.model';
import {ApiService} from './api.service';
import {EvaluationResponse} from '../_pages/evaluation-survey/abstract-evaluation-survey.component';


const LOCALSTORAGE_EVALUATION_KEY = 'evaluation';
const LOCALSTORAGE_EVALUATION_STEP = 'step';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  public responses$: Record<Survey, Subject<EvaluationResponse>>;
  protected evaluation: Evaluation | null;

  public constructor(
    protected currentUserService: CurrentUserService,
    protected apiService: ApiService,
  ) {
    this.evaluation = this.getCachedEvaluation();
    this.responses$ = {
      crisis: new Subject<EvaluationResponse>(),
      'auto-evaluation': new Subject<EvaluationResponse>(),
    };
  }

  public submitResponse(surveyType: Survey, slug: string, value: string): void {
    this.responses$[surveyType].next({slug, value});
  }

  public getEvaluation$(survey: Survey): Observable<Evaluation> {
    if (this.evaluation) {
      if (this.evaluation.survey === survey) {
        return of(this.evaluation);
      }

      this.reset();
    }

    return this.currentUserService.getCurrentUser().pipe(
      take(1),
      map(patient => {
        this.evaluation = new Evaluation();
        this.evaluation.survey = survey;
        this.evaluation.patient = Patient.getUriById(patient.id);
        this.evaluation.responses = Object.fromEntries(
          Array.from(
            {length: NUM_STEPS[survey]},
            (_, index) => [`question-${index + 1}`, null]
          )
        );
        return this.evaluation;
      })
    );
  }

  /**
   * Store evaluation in local storage (offline management)
   */
  public storeEvaluation(): void {
    this.evaluation.date = moment().format();
    localStorage.setItem(LOCALSTORAGE_EVALUATION_KEY, JSON.stringify(this.evaluation));
  }

  /**
   * Store step for later resume
   */
  public storeStep(step: number): void {
    localStorage.setItem(LOCALSTORAGE_EVALUATION_STEP, step.toString());
  }

  public getStep() {
    return localStorage.getItem(LOCALSTORAGE_EVALUATION_STEP);
  }

  /**
   * Remove evaluation from localStorage and reset
   */
  public reset(allowPost: boolean = true): void {
    localStorage.removeItem(LOCALSTORAGE_EVALUATION_KEY);
    localStorage.setItem(LOCALSTORAGE_EVALUATION_STEP, '0');
    if (allowPost && this.evaluation && this.evaluation.date) {
      this.apiService.postEvaluation(this.evaluation).subscribe();
    }
    this.evaluation = undefined;
  }

  /**
   * Search and retrieves the latest evaluation from the local storage if is set
   */
  protected getCachedEvaluation(): Evaluation | null {
    const evaluationPayload = localStorage.getItem(LOCALSTORAGE_EVALUATION_KEY);
    if (evaluationPayload === null) {
      localStorage.setItem(LOCALSTORAGE_EVALUATION_STEP, '0');
      return null;
    }

    const evaluation = EntityBuilder.buildOne<Evaluation>(Evaluation, JSON.parse(evaluationPayload)) as Evaluation;

    // If date is newer than 1 hour ago
    if (!evaluation.isOld()) {
      return evaluation;
    }

    this.apiService.postEvaluation(evaluation).subscribe();
    localStorage.removeItem(LOCALSTORAGE_EVALUATION_KEY);
    localStorage.setItem(LOCALSTORAGE_EVALUATION_STEP, '0');
    return null;
  }
}
