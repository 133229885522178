import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {CurrentUserService} from '../_services/current-user.service';
import {Patient} from '../_models/patient.model';
import {ModalController} from '@ionic/angular';
// import {LegalsModalComponent} from '../_components/modals/legals-modal/legals-modal.component';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  dataReturned: any;
  approvedLegal: false;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    public modalController: ModalController) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.currentUserService.getCurrentUser().pipe(
      filter((user: Patient | null | undefined) => user !== undefined),
      map((currentUser) => {
        if (currentUser && currentUser instanceof Patient) {
          // TODO: post mvp --> legalNotice
          // if (!currentUser.approvedLegalNotices) {
          //   this.openModal(currentUser).then();
          // }
          return true;
        }
        return this.router.createUrlTree(['/login'], {queryParams: {returnUrl: state.url}});
      }),
    );
  }

  // POST MVP OPEN MODAL LEGAL NOTICE
  // private async openModal(user: Patient) {
  //   const modal = await this.modalController.create({
  //     component: LegalsModalComponent,
  //     componentProps: {
  //       user
  //     },
  //     backdropDismiss:false
  //   });
  //   await modal.present();
  // }
}
