import {Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {AbstractEvaluationInput} from './abstract-evaluation-input';
import {EvaluationService} from '../../../_services/evaluation.service';
import {Survey} from '../../../_models/evaluation.model';

@Component({
  selector: 'app-range',
  template: `
    <ion-row>
      <ion-range mode="ios" min="0" max="10" pin="true" color="success" [value]="this.rangeValue"
                 (ionChange)="onIonChange($event)" #input>
      </ion-range>
      <ion-button #choiceRangeSubmitBtn appPreventDoubleTap [debounceTime]="700"
                  (debounceClick)="submitValue(entry.slug, this.rangeValue.toString())" class="validation-btn">
        Valider
      </ion-button>
    </ion-row>
  `,
  styleUrls: ['../abstract-evaluation-survey.component.scss']
})
export class RangeComponent extends AbstractEvaluationInput {
  @Input() public entry: {
    id: string;
    name: string;
    responses: string[];
    slug: string;
    text: string;
    type: string;
  };
  @Input() public surveyType: Survey;
  @ViewChildren('input') public inputs: QueryList<ElementRef>;
  public rangeValue = 0;

  constructor(
    protected evaluationService: EvaluationService,
  ) {
    super(evaluationService);
    this.initVal();
  }

  onIonChange($event: any) {
    this.rangeValue = $event.target.value;
  }

  public initVal() {
    this.rangeValue = 0;
  }

  public reset(entryType: string): void {
    this.initVal();
  }

}
