<ion-header class="header-page" translucent="true">
  <ion-toolbar style="position:sticky;">
    <ion-buttons slot="start" class="back-btn">
      <ion-icon mode="ios" name="arrow-back-outline" class="arrow-back-icon" (click)="previousStep()"></ion-icon>
    </ion-buttons>
    <ion-title><h2>Questionnaire</h2></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="questions$ | async as questions">
  <ng-container>
    <ion-grid *ngIf="!hasTime && surveyType === 'crisis'"
              [style.height]="hasTime ? '0' : '100vh'"
              [class]="hasTime ? 'hasTimeyes' : 'hasTimeno'">
      <div class="card-content">
        <app-chatbot [text]="'Merci de nous l\'avoir signalé.'"></app-chatbot>
        <app-chatbot [text]="'Pouvez-vous répondre à quelques questions supplémentaires ?'"></app-chatbot>
        <ion-row class="radio-col">
          <ion-col>
            <input type="radio" name="hasTime" value="1" id="hasTimeYes" (click)="(hasTime = true)"/>
            <label for="hasTimeYes" class="sfp700 thumbs-container">
              <ion-img src="assets/icon/eval-form-icons/thumb-up.png"></ion-img>
              <ion-text>OUI</ion-text>
            </label>
          </ion-col>
          <ion-col>
            <input type="radio" name="hasTime" value="0" id="hasTimeNo" [routerLink]="['/']"/>
            <label for="hasTimeNo" class="sfp700 thumbs-container">
              <ion-img src="assets/icon/eval-form-icons/thumb-down.png"></ion-img>
              <ion-text>NON</ion-text>
            </label>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </ng-container>
  <ion-grid class="window-content" #windowContent>
    <ng-container *ngFor="let question of questions; let i = index" #questionsOfEvaluation>
      <ng-container *ngIf="i < numEval[surveyType]">
        <ng-container *ngTemplateOutlet="questionsTemplate; context: {entry: question}"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container>
      <div class="card-content" #endOfEvaluation>
        <app-chatbot [text]="'Félicitations et merci pour vos réponses.'"></app-chatbot>
        <app-chatbot [text]="'Vous êtes arrivé(e) à la fin du questionnaire avec brio.'" [survey]="surveyType"></app-chatbot>
        <ion-button class="validation-btn" routerLink="">Retourner à l'accueil</ion-button>
      </div>
    </ng-container>
    <ng-container #stopEvaluation>
      <ion-grid class="window-content">
        <ion-row>
          <app-chatbot [text]="'Heureux que cette semaine se soit bien passée'" [survey]="surveyType"></app-chatbot>
          <ion-button class="validation-btn" color="primary" routerLink="">Retourner à l'accueil</ion-button>
        </ion-row>
      </ion-grid>
    </ng-container>
  </ion-grid>

  <!-- QUESTIONS TEMPLATES  -->
  <ng-template #questionsTemplate let-entry="entry">
    <div class="card-content">
      <ng-container *ngIf="chatbotStep.includes(entry.id)">
        <app-chatbot [entry]="entry"
                     [step]="step" class="transition-chatbot"
                     [survey]="surveyType"
                     [ngClass]="step+1 === entry.id ? 'chat-bot-visible' : 'chat-bot-not-visible'">
        </app-chatbot>
      </ng-container>
      <ion-row class="question-number text">
        <ion-text class="subtitle" color="medium" *ngIf="surveyType === 'auto-evaluation'">
          {{entry.name}}/13
        </ion-text>
        <ion-text class="subtitle" color="medium" *ngIf="surveyType === 'crisis'">
          {{entry.name}}/4
        </ion-text>
      </ion-row>
      <ion-row>
        <ion-text class="sfp600 text">{{entry.text}}</ion-text>
      </ion-row>

      <ng-container [ngSwitch]="entry.type">
        <!-- BOOLEAN QUESTIONS -->
        <ng-container *ngSwitchCase="'bool'">
          <app-bool #inputContainer [entry]="entry" [surveyType]="surveyType"></app-bool>
        </ng-container>

        <!-- RANGE QUESTIONS -->
        <ng-container *ngSwitchCase="'range'">
          <app-range #inputContainer [entry]="entry" [surveyType]="surveyType"></app-range>
        </ng-container>

        <!-- CHOICE QUESTIONS -->
        <ng-container *ngSwitchCase="'choice'">
          <app-choice #inputContainer [entry]="entry" [surveyType]="surveyType"></app-choice>
        </ng-container>

        <!-- CHOICE-RANGE QUESTIONS -->
        <ng-container *ngSwitchCase="'choice-range'">
          <app-choice-range #inputContainer [entry]="entry" [surveyType]="surveyType"></app-choice-range>
        </ng-container>
      </ng-container>


    </div>
  </ng-template>
</ion-content>
