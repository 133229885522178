import {AbstractEntity} from '@forlabs/api-bridge';
import {Type} from '@decahedron/entity';
import {Patient} from './patient.model';

export class Center extends AbstractEntity {
  public name: string = null;

  // @ts-ignore
  @Type(() => require('./patient.model'))
  public patients: Patient[] = null;


}
