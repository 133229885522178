import {Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {AbstractEvaluationInput} from './abstract-evaluation-input';
import {EvaluationService} from '../../../_services/evaluation.service';
import {Survey} from '../../../_models/evaluation.model';

@Component({
  selector: 'app-choice',
  template: `
    <ion-row *ngFor="let answer of entry.responses; let indexOfElement = index">
      <ng-container *appLet="getBaseDomId() + entry.responses[indexOfElement].toString() as id">
      <input #choiceRangeSubmitBtn appPreventDoubleTap [debounceTime]="700"
             #input type="radio" [name]="entry.slug" [id]="id"
             [value]="indexOfElement" (debounceClick)="submitValue(entry.slug, indexOfElement)"/>
      <label [for]="id" class="sfp400 ofThree-container">
        <ion-text class="text">{{answer}}</ion-text>
      </label>
      </ng-container>
    </ion-row>
  `,
  styleUrls: ['../abstract-evaluation-survey.component.scss']
})
export class ChoiceComponent extends AbstractEvaluationInput {
  @Input() public entry: {
    id: string;
    name: string;
    responses: string[];
    slug: string;
    text: string;
    type: string;
  };
  @Input() public surveyType: Survey;
  @ViewChildren('input') public inputs: QueryList<ElementRef>;

  constructor(
    protected evaluationService: EvaluationService,
  ) {
    super(evaluationService);
  }
}
