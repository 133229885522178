import {Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {Evaluation, NUM_STEPS, Survey} from '../../_models/evaluation.model';
import {delay, delayWhen, Observable, retryWhen, shareReplay, Subscription, tap, timer} from 'rxjs';
import {Question} from '../../_models/question.model';
import {QuestionDataService} from '../../_services/data-services/question-data.service';
import {Router} from '@angular/router';
import {EvaluationService} from '../../_services/evaluation.service';
import {ApiService} from '../../_services/api.service';
import {filter, take} from 'rxjs/operators';
import {AbstractEvaluationInput} from './evaluation-input/abstract-evaluation-input';
import {AlertController, ToastController} from '@ionic/angular';


export interface EvaluationResponse {
  slug: string;
  value: string;
}

@Component({
  selector: 'app-evaluation-survey',
  templateUrl: './abstract-evaluation-survey.component.html',
  styleUrls: ['./abstract-evaluation-survey.component.scss'],
})
export class AbstractEvaluationSurveyComponent implements OnInit {
  @Input() public stepStorage: number;
  @ViewChild('windowContent', {static: false, read: ElementRef}) windowContent: ElementRef;
  @ViewChild('stopEvaluation', {static: false, read: ElementRef}) stopEvaluation: ElementRef;
  @ViewChild('questionsOfEvaluation', {static: false, read: ElementRef}) questionsOfEvaluation: ElementRef;
  @ViewChild('endOfEvaluation', {static: false, read: ElementRef}) endOfEvaluation: ElementRef;
  @ViewChildren('inputContainer') inputContainers: QueryList<ElementRef<AbstractEvaluationInput>>;

  public surveyType: Survey;
  public step = 0;
  public chatbotStep = [1, 3, 7, 9, 13];
  public questions$: Observable<Question[]>;
  public isEvalStop = false;
  public hasTime = false;
  public numEval = NUM_STEPS;
  public id: number;

  public initialized = false;
  public subscriptions: Subscription = new Subscription();

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private questionDataService: QuestionDataService,
    private router: Router,
    private evaluationService: EvaluationService,
    private render: Renderer2,
    private apiService: ApiService,
  ) {
    this.questions$ = this.questionDataService.loadAll().pipe(
      filter(questions => questions.length > 0),
      shareReplay(1),
    );
  }

  public ngOnInit(): void {
    this.initialized = true;
    this.subscriptions.add(
      this.evaluationService.responses$[this.surveyType].subscribe(
        (evaluationResponse: EvaluationResponse) => this.submit(evaluationResponse)
      )
    );
  }

  public ionViewWillEnter(): void {
    this.subscriptions.add(
      this.evaluationService.getEvaluation$(this.surveyType).subscribe()
    );
    this.step = parseInt(this.evaluationService.getStep(), 10);
  }

  public ionViewDidEnter() {
    this.subscriptions.add(
      this.questions$.pipe(
        take(1),
        // Make sure that the *ngIf on this.questions$ has already been processed, because that's what populates
        // this.windowContent, which is needed below.
        delay(10),
        tap(() => {
          if (this.step !== 0) {
            this.scroll(false);
          }
        })
      ).subscribe()
    );
  }

  public ionViewWillLeave() {
    if (this.step === 0) {
      this.scroll(false);
      this.evaluationService.reset();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public previousStep(): void {
    if (this.step > 0) {
      this.step = this.step - 1;
      this.render.setStyle(this.windowContent.nativeElement.children[this.step], 'height', '100vh');
      this.scroll();
    } else if (this.step === 0) {
      this.router.navigate(['evaluations']).then(
        () => this.evaluationService.reset()
      );
    }
  }

  protected scroll(smooth: boolean = true) {
    // 15ms is generally enough of an initial delay for it to work (on chromium desktop at least).
    const subscription = timer(15).pipe(
      tap(
        () => {
          this.windowContent.nativeElement.children[this.step].scrollIntoView(smooth ? {behavior: 'smooth', block: 'start'} : undefined);
          // "Weak" check that scrollIntoView was successful
          if (this.step === 0 || this.windowContent.nativeElement.scrollTop > 0) {
            return;
          }
          // No scroll happened
          throw 'no-scroll';
        },
      ),
      retryWhen(
        errors => errors.pipe(
          // Add a 85ms delay (100ms total) to retry attempts.
          delayWhen(() => timer(85)),
        )
      )
    ).subscribe();
    this.subscriptions.add(subscription);
  }

  protected submit(evaluationResponse: EvaluationResponse) {
    const step = this.step + 1;
    this.step = step;
    this.evaluationService.storeStep(step);
    this.evaluationService.getEvaluation$(this.surveyType).pipe(
      take(1),
    ).subscribe(evaluation => {
      if (evaluation.isOld()) {
        this.presentToastAndReset('Formulaire expiré. L\'évaluation a été correctement enregistrée avec les réponses précédemment renseignées.')
          .then(() => this.router.navigate(['']));
        return;
      }
      if (step <= NUM_STEPS[this.surveyType]) {
        evaluation.responses[evaluationResponse.slug] = parseInt(evaluationResponse.value, 10);
        this.evaluationService.storeEvaluation();
        // Case where user answers "NO" to the first 2 questions for "auto-evaluation"
        if (evaluation.responses['question-1'] === 0 && evaluation.responses['question-2'] === 0 && this.surveyType === 'auto-evaluation') {
          this.windowContent.nativeElement.children[this.windowContent.nativeElement.children.length - 1].scrollIntoView();
          evaluation.responses = Object.fromEntries(
            Array.from(
              {length: NUM_STEPS[this.surveyType]},
              (_, index) => [`question-${index + 1}`, 0]
            )
          );
          this.postEvaluation(evaluation);
          this.resetInputs();
        } else {
          const elem = this.windowContent.nativeElement.children[step];
          if (elem.children[0].nodeName === 'APP-CHATBOT') {
            this.render.setStyle(this.windowContent.nativeElement.children[step - 1], 'height', '64vh');
          }

          setTimeout(() => {
            this.scroll();
          }, 800);
        }
      }
      if (step === NUM_STEPS[this.surveyType]) {
        this.postEvaluation(evaluation);
      }
    });

    // TODO: check that evaluation has responses for every previous step (here or somewhere else?)
  }

  protected postEvaluation(evaluation: Evaluation): void {
    this.evaluationService.reset(false);
    this.apiService.postEvaluation(evaluation).subscribe({
      next: () => this.presentToastAndReset(),
      error: () => this.onPostEvaluationError(),
    });
  }

  protected presentToastAndReset(message: string = null): Promise<void> {
    return this.presentToast(message ?? 'Evaluation enregistrée avec succès')
      .then(() => this.evaluationService.reset())
    ;
  }

  protected onPostEvaluationError() {
    this.alertController.create({
      header: 'Error',
      message: `Erreur durant l\'évaluation`,
      buttons: ['OK'],
    }).then(alert => alert.present());
  }

  protected resetInputs(): void {
    this.inputContainers.forEach((inputContainer:  ElementRef<AbstractEvaluationInput>) => {
      const inputContainerOverride = (inputContainer as unknown as AbstractEvaluationInput);
      const entryType = inputContainerOverride.entry.type;
      inputContainerOverride.reset(entryType);
    });
  }

  protected presentToast(message: string): Promise<void> {
    return this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    }).then(toast => toast.present());
  }
}
