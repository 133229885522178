import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-chatbot',
  template: `
    <ion-row class="chatbot transition-chatbot">
      <div class="icon-chatbot">
        <ion-img src="assets/icon/eval-form-icons/ear-icon.svg"></ion-img>
      </div>
      <div class="speech-bubble">
        <ng-container *ngIf="entry; else justText">
        <ion-text *ngIf="entry.id !== 1; else crisisOrEval" class="chatbot-text">{{'evaluation.chatbot.' + entry.id | translate}}</ion-text>
          <ng-template #crisisOrEval>
            <ng-container *ngIf="survey === 'auto-evaluation'">
              <ion-text class="chatbot-text">{{'evaluation.chatbot.1.autoEvaluation' | translate}}</ion-text>
            </ng-container>
            <ng-container *ngIf="survey === 'crisis'">
              <ion-text class="chatbot-text">{{'evaluation.chatbot.1.crisis' | translate}}</ion-text>
            </ng-container>
          </ng-template>
        </ng-container>
        <ng-template #justText>
        <ion-text class="chatbot-text">{{text}}</ion-text>
        </ng-template>
      </div>
    </ion-row>
  `,
  styleUrls: ['./abstract-evaluation-survey.component.scss']
})
export class ChatbotComponent {
  @Input() label;
  @Input() entry;
  @Input() text;
  @Input() step;
  @Input() survey;

}
