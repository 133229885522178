import {Injectable} from '@angular/core';
import {OnlineOfflineService} from './online-offline.service';
import {ApiService} from './api.service';
import {catchError, filter, takeUntil, tap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';


const LOCALSTORAGE_REQUEST_QUEUE_KEY = 'request_queue';
type RequestData = {
  method: 'POST'; // | 'PATCH' | 'PUT' | 'DELETE',
  uri: string;
  data: any;
};


@Injectable({
  providedIn: 'root'
})
export class ApiRequestQueueService {
  protected requestQueue: RequestData[];
  protected apiService: ApiService;

  constructor(
    protected onlineOfflineService: OnlineOfflineService,
  ) {
    this.requestQueue = JSON.parse(localStorage.getItem(LOCALSTORAGE_REQUEST_QUEUE_KEY)) ?? [];

    this.onlineOfflineService.connectionChanged$.pipe(
      filter(online => online),
      tap(() => this.onConnectionOnline()),
    ).subscribe();
  }

  public initApiService(apiService: ApiService): void {
    this.apiService = apiService;
    if (this.onlineOfflineService.isOnline) {
      this.onConnectionOnline();
    }
  }

  public queueRequest(method: RequestData['method'], uri: string, data: any): void {
    // TODO: check that data is serializable
    this.requestQueue.push({
      method,
      uri,
      data,
    });

    localStorage.setItem(LOCALSTORAGE_REQUEST_QUEUE_KEY, JSON.stringify(this.requestQueue));
  }

  public onConnectionOnline(): void {
    if (this.requestQueue.length === 0) {
      return;
    }

    this.handleFirstRequest().pipe(
      catchError(err => {
        if (err?.error?.uuid === 'c1e39930-22c1-11ec-9621-0242ac130002') {
          console.log('HTTP status 409 on POST due to duplicate evaluation. We can safely ignore this error, the evaluation is already saved.');
          return of(null);
        }
        throwError(err ?? 'Error while processing queued request.');
      }),
      // If we go offline, abort.
      takeUntil(this.onlineOfflineService.connectionChanged$),
      // If we managed to re-send the quest without going offline, remove if from the queue and handle the next request.
      tap(() => {
        // Remove first request from request queue.
        this.requestQueue.shift();
        localStorage.setItem(LOCALSTORAGE_REQUEST_QUEUE_KEY, JSON.stringify(this.requestQueue));
        // Handle the next request.
        this.onConnectionOnline();
      }),
    ).subscribe();
  }

  protected handleFirstRequest(): Observable<any> {
    const {uri, method, data}: RequestData = this.requestQueue[0];
    switch (method) {
      case 'POST':
        return this.apiService.post(uri, data, {errorPassthrough: true});
      default:
        throw new Error(`ApiRequestQueueService: Method ${method} is not implemented.`);
    }
  }
}
