import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from './_services/api.service';
import {CurrentUserService} from './_services/current-user.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationStart, Router} from '@angular/router';
import {IdleService} from './_services/idle.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  subscription: Subscription;
  browserRefresh = false;
  constructor(
    public route: Router,
    protected currentUserService: CurrentUserService,
    protected apiService: ApiService,
    protected translate: TranslateService,
    private idleService: IdleService,
  ) {
    translate.setDefaultLang('fr');
    this.subscription = route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !route.navigated;
        if(this.browserRefresh) {
          this.idleService.idleCount(1200)
        }
      }
    });
  }

  ngOnInit() {
    // search for cache storage (CacheStorage)
    // Manage the caches : clear the old, or, useless caches
    caches.keys().then((cacheNames) => Promise.all(
      cacheNames.map((cacheName) => {
        if ('/' !== cacheName && 'test' === cacheName)  {
          return caches.delete(cacheName);
        }
      })
    ));


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
