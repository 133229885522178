import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

declare var window: any;

@Injectable()
export class EnvLoaderService {
    // This is the method you want to call at bootstrap
    // Important: It should return a Promise
    public init(): Promise<object> {
        return from(
            fetch('assets/environment.json').then((response: Response) => response.json())
        ).pipe(map((environment: object) => {
            window.environment = environment;
            return environment;
        })).toPromise();
    }
}
