import {AbstractEntity} from '@forlabs/api-bridge';

export class Appointment extends AbstractEntity{
  public date: string = null;
  public notificationSent: string = null;

  // @Type(Patient)
  // @Type(() =>require('./patient.model'))
  // public patient: Password = null;
}
