import {Injectable} from '@angular/core';
import {AbstractEntitydataService} from '@forlabs/api-bridge';
import {Question} from '../../_models/question.model';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionDataService extends AbstractEntitydataService<Question>{
  constructor(private apiService: ApiService) {
    super();
  }

  protected getLoadObservable(id: number | string): Observable<Question> {
    return this.apiService.getQuestion(id as string);
  }

  protected getLoadAllObservable(): Observable<Question[]> {
    return this.apiService.getQuestions();
  }
}
