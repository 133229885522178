import {AbstractEntity} from '@forlabs/api-bridge';

export class Question extends AbstractEntity {
  public get uri(): string {
    return Question.getUriById(this.id);
  }

  public slug: string = null;
  public text: string = null;
  public type: string = null;
  public name: string = null;
  public responses: string[] = [];

  public static getEntityName(): 'api/questions' {
    return 'api/questions';
  }
}
