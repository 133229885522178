import {Injectable, Injector} from '@angular/core';
import {AbstractCurrentUserService, TokenService} from '@forlabs/api-bridge';
import {Patient} from '../_models/patient.model';
import {Observable, of} from 'rxjs';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService extends AbstractCurrentUserService<Patient> {
  protected apiLoginUrl = 'authentication_token';
  private error: Record<string, any>;

  public constructor(tokenService: TokenService, injector: Injector, public toastController: ToastController) {
    super(tokenService, injector);
  }

  public postRefreshToken(data: {refresh_token: string}): Observable<any> {
    return this.apiClient.post<any>('token/refresh', data);
  }

  public onInvalidCredential(error: Record<string, any>): Observable<void> {
    this.presentToast(error).then();
    this.error = error;
    return of();
  }

  async presentToast(error) {
    const toast = await this.toastController.create({
      message: error['hydra:description'],
      duration: 2000
    });
    await toast.present();
  }

}
