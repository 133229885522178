import {AbstractUser} from '@forlabs/api-bridge';
import {Type} from '@decahedron/entity';
import {Center} from './center.model';
import {Role} from './interfaces.model';

export class Investigator extends AbstractUser {
  public email: string = null;
  public role: Role;
  public lastName: string = null;
  public firstName: string = null;
  @Type(Center) public center: Center = null;

  // @Type(() => require('./patient.model'))
  // @Type(Patient)
  // FIXME: typehint relation
  public patients: string = null;

  public static getEntityName(): 'api/investigators' {
    return 'api/investigators';
  }

  public get uri(): string {
    return Investigator.getUriById(this.id);
  }

  public getRoles(): string[] {
    return [this.role];
  }
}
